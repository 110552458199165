<template>
  <div class="card rdv" @click="$emit('clicked')">
    <div class="card__text">
      <p class="card__title">{{ event.type }}</p>
      <p class="card__lieu">{{ event.lieu }}</p>
    </div>
    <div>
      <p class="card__date">
        {{ date }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Card",
  props: {
    event: Object
  },
  data() {
    return {
      // date: "24 septembre"
    };
  },
  computed: {
    date() {
      const date = this.event.date.split("-").reverse();
      const month = [
        "janvier",
        "février",
        "mars",
        "avril",
        "mai",
        "juin",
        "juillet",
        "aout",
        "septembre",
        "octobre",
        "novembre",
        "décembre"
      ];

      return `${date[0]} ${month[parseInt(date[1]) - 1]}`;
    }
  }
};
</script>

<style scoped lang="scss">
.card {
  margin-bottom: 20px;
  padding: 20px;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  font-size: 13px;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
  box-shadow: 0px 10px 10px rgba(13, 78, 129, 0.051);

  &::before {
    content: "";
    height: 100%;
    width: 4px;
    background-color: #fbd1d1;
    position: absolute;
    left: 0;
    top: 0;
  }
  &__lieu {
    color: #bababa;
  }
  &__date {
    color: #95cdcb;
    font-weight: 600;
  }
}

.rdv {
  padding: 10px 20px;
}
</style>
