<template>
  <div class="header" :class="{ header__app: app }">
    <h2 class="title">{{ title }}</h2>
  </div>
</template>

<script>
export default {
  name: "Header",
  props: {
    title: {
      type: String,
      default: ""
    },
    app: {
      type: Boolean,
      default: false
    }
  }
};
</script>

<style lang="scss">
.header {
  background-image: url("../assets/header.svg");
  background-size: cover;
  z-index: 8;
  padding: 35px;
  background-repeat: no-repeat;
  background-position: center 100%;

  &__app {
    text-transform: uppercase;
    padding: 30px;
  }
}

.header p {
  width: 15em;
  margin: auto;
  color: #707070;
}

.title {
  font-size: 18px;
  font-weight: 400;
  color: #707070;
  width: 15em;
  margin: auto;
}
@media (min-width: 850px) {
  .header {
    background-image: none;
    z-index: -1;
    padding-top: 20px;
    &::before {
      content: " ";
      background-size: contain;
      z-index: -1000;
      height: 100%;
      background-repeat: no-repeat;
      position: absolute;
      left: 0px;
      width: 100%;
      bottom: 0px;
      background-position: top;
      background-image: url("../assets/header_dashboard.svg");
    }
  }
  .title {
    width: 14em;
    text-align: center;
    margin: auto;
    font-size: 20px;
  }
}
</style>
