<template>
  <div class="empty">
    <button @click="addHandler">Add event / agenda</button>
  </div>
</template>

<script>
export default {
  name: "EmptyState",
  methods: {
    addHandler() {
      this.$store.commit("setModal");
      console.log("[Add] Clicked");
    }
  }
};
</script>

<style></style>
